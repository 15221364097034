import React from 'react';
import '../App.css';
import './Portfolio.css';

function Portfolio() {
  return (
    <div class='port-container'>
          {/* <video src='/videos/JumperFanCut2.mp4' autoPlay loop muted /> */}
          <div class='port-container-items'>
              <img src="images/mars.jpg" />
              <img src="images/A7.jpg" />
              <img src="images/jumper4.jpg" />
            </div>
            <div class='port-container-items'>
              <img src="images/A18.jpg" />
              <img src="images/judy.jpeg" />
              <img src="images/A1.jpg" />
            </div>
            <div class='port-container-items'>
              <img src="images/A3.jpg" />
              <img src="images/A4.jpg" />
              <img src="images/nature1.jpg" />
            </div>
            <div class='port-container-items'>
              <img src="/images/image3.PNG"/>
              <img src="images/fluidPic.PNG" />
              <img src="images/A2.PNG" />
              </div>
              <div class='port-container-items'>
              <img src="images/A5.jpg" />
              <img src="images/capstan1.PNG" />
              <img src="images/A9.jpg" />
            </div>
            <div class='port-container-items'>
              <img src="images/A6.jpg" />
              <img src="images/jumper3.PNG" />
              <img src="images/A8.jpg" />
            </div>
            <div class='port-container-items'>
              <img src="images/jumper2.PNG" />
              <img src="images/marsjumper.JPEG" />
              <img src="images/A17.jpg" />
            </div>
            <div class='port-container-items'>
              <img src="images/A19.jpg" />
              <img src="images/A30.PNG" />
              <img src="images/DSC-0489.JPG" />
            </div>

        
    </div>
    
  );
}

export default Portfolio;
