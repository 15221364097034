import React from 'react';
import '../../App.css';
// export default function Hardware() {
//   return <h1 className='hardware'>HARDWARE</h1>;
// }
import HardwareExp from '../HardwareExp';



function Hardware() {
  return (
    <>
      <HardwareExp />
    </>
  );
}

export default Hardware;
