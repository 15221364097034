import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        
        <p className='footer-subscription-text'>
          Here's some links to things I am/have been involved in. 
        </p>

      </section>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <a href="https://marsconference.com/" target="_blank">MARS 2023 </a>
            <a href="https://www.sxsw.com/" target="_blank">SXSW 2023 </a>
            <a href="https://www.nature.com/articles/s41586-022-04606-3.epdf?sharing_token=iwOWfzCrg84fVTvPnzS6k9RgN0jAjWel9jnR3ZoTv0NoCvlhBLYcl3v5dmxMf_KV4fMB6StwnBM7o-Iccw29Ng5B50g02eXLxhfTQhAiolhV_TBnJLGKC0YfSS8TRmKCfqfqRa5EgTBkFBoWUbTd-BUujlkDe9M0ai02nX1zdA8%3D" target="_blank">Nature Journal Paper</a>
            <a href="https://www.hawkeslab.com/" target="_blank">UCSB Hawkes Lab  </a>
            <a href="https://www.youtube.com/watch?v=daaDuC1kbds&t=640s" target="_blank">Veritasium video about our jumper  </a>
            <a href="https://ieeexplore.ieee.org/abstract/document/9834065" target="_blank">Miniature Capstan Winch  </a>
            <a href="https://www.dressairelab.com/" target="_blank">UCSB Bio-Inspired Fluid Flow Lab  </a>
            <a href="https://www.anvilstartups.com/theboiler" target="_blank">Purdue Accelerator Program  </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
