import React from 'react';
import '../../App.css';
import Portfolio from '../Portfolio';



function PhotoPortfolio() {
  return (
    <>
      <Portfolio />
    </>
  );
}

export default PhotoPortfolio;
