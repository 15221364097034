import React from 'react';
import '../../App.css';
import SoftwareExp from '../SoftwareExp';



function Software() {
  return (
    <>
      <SoftwareExp />
    </>
  );
}

export default Software;
