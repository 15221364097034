import React from 'react';
import '../App.css';
import './SoftwareExp.css';

function SoftwareExp() {
  return (
    <div className='soft-container'>
      {/* <video src='/videos/JumperFanCut2.mp4' autoPlay loop muted /> */}
        <h1>This Website</h1>
         <div class='soft-container-items'>
            <p> Made with React framework, this website is built with javascript and CSS. My first experience with web design was while participating in a startup accelerator program from Purdue. I was the team lead on that project and mostly dealt with the business side, but my work on the website did include UI/UX design, and working with tools like 
              Illustrator, Figma, Pixlr, Canva, and Photoshop. I did however design and publish the simple placeholder page, which is the only thing currently public: <a href="http://forregulars.com.s3-website-us-east-1.amazonaws.com/" target="_blank">forregulars.com </a>
            </p>
            <img src="images/whitePic.png" /> 
                <img src="images/reactPic.png" /> 
          </div>
            <h1>SolidWorks</h1>
              <div class='soft-container-items'>
                <p> My most familiar software. I am fluent in the main functionality of SolidWorks. I am in the process of familiarizing myself with more niche features like SolidCAM and surface modeling. Every 
                  project under my "Hardware Experience" page involved at least some use of SolidWorks CADD. 
                </p>
                <img src="images/whitePic.png" /> 
                <img src="images/SWpic.png" /> 
              </div>
            <h1>Matlab</h1>
              <div class='soft-container-items'>
              <p>I am effective with Matlab and improving my familiarity with Simulink. My experience with Simulink has been primarily solid body simulations for applications in robotics. 
              </p>
              <img src="images/whitePic.png" /> 
              <img src="images/matlabPic.png" />
              
            </div>
            <h1>Arduino</h1>
              <div class='soft-container-items'>
              <p>I've used ArduinoUno to explore closed loop control, image processing, IMU sensing, and more. I continue to work closely with ArduinoUnos on other projects as well and regularly use it for simple prototyping.
             </p>
             <img src="images/ArduinoUnoPic.jpg" />
             <img src="images/ArduinoMotorcycle.PNG" />
             <img src="images/ArduinoUnoDraw.jpg" />
              </div>
            <h1>Python</h1>
              <div class='soft-container-items'>
                <p>I can effectively work with existing python code bases. I am proficient with python to where I can write scripts to help with basic prototyping, streamlining data-processing stuff, etc. </p>
                <img src="images/whitePic.png" />  
                <img src="images/PythonLogo.png" />
                
                
                </div>
                
      </div>
    
  );
}

export default SoftwareExp;
