import React from 'react';
import '../App.css';
import './HardwareExp.css';

function HardwareExp() {
  return (
    <div class='hard-container'>
          {/* <video src='/videos/JumperFanCut2.mp4' autoPlay loop muted /> */}
          <h1>Disney Robot</h1>
          <div class='hard-container-items'>
              <p> Showcased at SXSW 2023, this robot based on a popular disney character is able to stand up, fall over, and stand up again. My involvement is ongoing and my work so far has been primarily on the mechanical design of the robot, as well
                as the animation development side - which includes working in Maya, MotionBuiler, Simscape, and OptiTrack motion capture systems. 
              </p>
              
              <img src="images/judy.JPEG" />
              <img src="images/judy2.jpg" />
              
            </div>
          <h1>Jumping Robot</h1>
           <div class='hard-container-items'>
              <p> Published in Nature magazine, the jumping robot is where I've put more time than any other project. It relies on work multiplication to 
                compress its carbon fiber legs using a 10 gram motor. The whole robot weighs less than 40 grams and jumps over 100 feet high. Before I graduated, I began working on giving the jumper added 
                funcitonality. With the addition of 3 seperately actuared legs and a camera, the jumper can stand-up, aim, and see.  
              </p>
              
              <img src="images/jumper2.PNG" />
              <img src="images/jumper3.PNG" />
              <img src="images/jumper4.jpg" />
              
              
              
              
            </div>
            <h1>Soft-Substrate with Fluid Plug</h1>
            <div class='hard-container-items'>
              <p>In the BIFF lab at UCSB, I examined the impact that soft-substrates (ie, gel coated surfaces) have on the flow of a liquid plug, as opposed to the interactions
                we commonly observe with hard substrates (like a steel plate) on the fluid flowing within them. This work isn't scheduled for publication just yet so I can't be too specific. 
              </p>
              <img src="images/whitePic.PNG" />
              <img src="images/fluidPic.PNG" />
            </div>
            <h1>Micro-Winch</h1>
            <div class='hard-container-items'>
              <p>This project expanded upon one of the key mechanisms of the jumping robot. The capstan winching action we utilized to
                compress the jumper's legs without necessitating a larger motor capable of overcoming the increase in torque that accompanies a 
                drum winches constantly increasing radius was extracted from the jumper and made more robust for the micro-winch project. Where the jumper could only winch ~30 cm of cord, 
                the micro-winch is able to winch indefinetely. 
              </p>
              
              <img src="images/capstan1.PNG" />
              <img src="images/capstan2.PNG" />
            </div>
            <h1>Soft Material Iris Seal</h1>
            <div class='hard-container-items'>
              <p>Traditional Iris Seals use rigid materials to accomplish their dilating action. This subjects the Iris to significantly limited operation lifetime in the event they
                are used in the presence of fluid (like inside the body) or granular media (like lunar regolith). To solve this, I developed a simple Iris mechanism using an elastic material in lieu of the metallic irises 
                currently used. Although the mechanism works, it's "unproven" capability made it unusable on our spacebound capstone project.  I have not been able to refine it past the early 
                development stage but I've included it hear because I think it's neat and was a fun + quick project. 
                
              </p>
              <img src="/images/image3.PNG"/>
              </div>

        
    </div>
    
  );
}

export default HardwareExp;
